<template>
  <div class="member-index">
    <!-- <img src="@/assets/swiper1.jpg" class="vip-banner" /> -->
    <el-tabs>
      <el-tab-pane>
        <div class="custom-label" slot="label">
          <svg-icon icon-class="vip"></svg-icon>
          <span>用户会员</span>
        </div>
        <div class="vip-content">
          <el-row>
            <el-col :span="6" class="br-1">
              <el-form class="vip-desc">
                <h3>VIP特权</h3>
                <el-form-item label=""
                  >1.会员享有购买平台产品9折优惠</el-form-item
                >
                <el-form-item label=""
                  >2.会员上传户型，在原本分成的基础上多提升十个点。</el-form-item
                >
                <el-form-item label=""
                  >3.会员用户可以直接点击卖家用户名，查看是否显示联系方式。</el-form-item
                >
              </el-form>
              <!-- <p class="center">了解更多特权</p> -->
            </el-col>
            <el-col :span="18">
              <div class="vip-price">
                <el-row :gutter="20">
                  <el-col :span="6" v-for="item in list" :key="item.id">
                    <!-- <el-badge :value="item.subTitle"> -->
                    <el-card
                      class="price"
                      :class="{ checked: type === item.id }"
                      @click.native="changeType(item)"
                    >
                      <div>
                        ￥
                        <b>{{ item.money }}</b>
                      </div>
                      <p>{{ item.title }}</p>
                      <!-- <span>{{ item.desc }}</span> -->
                    </el-card>
                    <!-- </el-badge> -->
                  </el-col>
                  <el-col :span="6">
                    <el-card
                      class="price"
                      :class="{ checked: type === 'custom' }"
                      @click.native="changeType(null, 'custom')"
                    >
                      <div>
                        ￥
                        <b>{{ base }}</b>
                      </div>
                      <el-input-number
                        size="mini"
                        @click.stop
                        v-model="customMonth"
                        :min="1"
                        :max="36"
                        @change="getPrice"
                      ></el-input-number>
                    </el-card>
                  </el-col>
                </el-row>
              </div>
              <div class="price-qr">
                <el-button
                  style="margin-top: 80px"
                  type="primary"
                  @click="onConfirm"
                  >立即购买</el-button
                >
                <!-- <vue-qr :text="orderPath" :size="200"></vue-qr>
                <div>
                  ￥
                  <b>{{ totalMoney }}</b>
                </div>
                <p>支持微信/支付宝</p> -->
              </div>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
      <el-dialog
        :visible.sync="payDialog"
        @close="beforeClose"
        width="600px"
        title="VIP购买"
        v-if="userinfo"
      >
        <el-form label-width="80px" size="mini">
          <el-form-item label="订单编号">
            {{ orderNo }}
          </el-form-item>
          <el-form-item label="我的硬币">
            {{ userinfo.money }}
          </el-form-item>
        </el-form>
        <div class="price-qr">
          <el-button
            type="primary"
            v-if="payType === 'yingbi'"
            @click="payWithCoin"
            >立即购买</el-button
          >
          <template v-else>
            <vue-qr :text="orderPath" :size="200"></vue-qr>
          </template>
          <div>
            ￥
            <b>{{ money }}</b>
          </div>
          <p>
            支付方式
            <el-radio-group v-model="payType" @change="createOrder">
              <el-radio label="yingbi">硬币</el-radio>
              <el-radio label="wechat">微信</el-radio>
              <!-- <el-radio label="alipay">支付宝</el-radio> -->
            </el-radio-group>
          </p>
          <!-- <p>支持微信/支付宝</p> -->
          <!-- <p class="tips">已购买的素材在30天内可重复下载</p> -->
        </div>
      </el-dialog>
      <!-- <el-tab-pane>
        <div class="custom-label" slot="label">
          <svg-icon icon-class="vip"></svg-icon>
          <span>年会员</span>
        </div>
        <el-empty description="功能开发中~"></el-empty>
      </el-tab-pane>
      <el-tab-pane>
        <div class="custom-label" slot="label">
          <svg-icon icon-class="vip"></svg-icon>
          <span>企业会员</span>
        </div>
        <el-empty description="功能开发中~"></el-empty>
      </el-tab-pane> -->
    </el-tabs>
    <div class="vip-tips">
      <p>
        <b>平台说明：</b>
      </p>
      <p>1.平台只提供会员充值，不提供硬币充值。</p>
      <p>2.平台硬币只能通过上传平台所需商品进行获得。</p>
      <p>3.硬币可以等价支付平台商品，1硬币=1元。</p>
      <p>4.硬币金额永久有效，可以提现。</p>
      <p>
        5.用户在平台购买平台商品后，可以点击卖家用户名，查看卖家是否显示联系方式。
      </p>
    </div>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import { addVip, queryOrderState, getVipMoney, payCoin } from "@/api";
import { mapGetters } from "vuex";
export default {
  components: {
    VueQr,
  },
  data() {
    return {
      orderPath: "",
      totalMoney: 0,
      type: 1,
      base: 28,
      customMonth: 1,
      isCustom: false,
      month: 12,
      list: [
        {
          id: 1,
          money: 183,
          month: 12,
          title: "1年",
          subTitle: "加赠3个月",
          desc: "低至￥0.75/天",
        },
        {
          id: 2,
          money: 95,
          month: 6,
          title: "6个月",
          subTitle: "9折",
          desc: "低至￥0.84/天",
        },
        {
          id: 3,
          money: 49,
          month: 3,
          title: "3个月",
          subTitle: "9折",
          desc: "低至￥0.84/天",
        },
      ],
      payDialog: false,
      payType: "yingbi",
      orderNo: "",
      money: "",
    };
  },
  created() {
    this.getPrice();
    // this.list.forEach((item) => (item.money = this.base * item.month));
  },
  computed: {
    ...mapGetters(["userinfo"]),
  },
  methods: {
    async getPrice() {
      const res = await getVipMoney({
        month: this.customMonth,
      });
      this.base = res;
    },
    beforeClose() {
      clearTimeout(this.timer);
      this.timer = null;
      this.orderNo = "";
    },
    async payWithCoin() {
      payCoin({
        OrderNo: this.orderNo,
      }).then(() => {
        this.queryState();
      });
    },
    async queryState() {
      if (!this.orderNo) return;
      clearTimeout(this.timer);
      queryOrderState({
        OrderNo: this.orderNo,
      }).then((res) => {
        // if ()
        if (res === "unpaid") {
          this.timer = setTimeout(() => {
            this.queryState();
          }, 500);
        } else if (res === "success") {
          this.$message.success("购买成功");
          // this.download();
          location.reload();
        } else if (res === "pastdue") {
          this.createOrder();
        }
      });
    },
    async createOrder() {
      const res = await addVip({
        month: this.month,
        type: this.payType,
      });
      this.orderPath = res.code;
      this.orderNo = res.OrderNo;
      this.money = res.money;
      if (this.payType !== "yingbi") {
        this.$nextTick(() => {
          this.queryState();
        });
      }
    },
    changeType(item, type) {
      if (this.type === type || this.type === item?.id) return;
      if (!item) {
        this.type = type;
        this.isCustom = true;
      } else {
        this.type = item.id;
        this.isCustom = false;
      }
      // this.createOrder();
    },
    onConfirm() {
      if (this.type === "custom") {
        this.month = this.customMonth;
      } else {
        this.month = this.list.find((item) => item.id === this.type).month;
      }
      if (!this.$store.state.userinfo) {
        return false;
      }
      this.orderNo = "";
      this.orderPath = "";
      this.money = "";
      this.createOrder();
      this.payDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.member-index {
  background: #fff;
  padding: 20px;
}
.vip-banner {
  width: 100%;
  height: 100px;
  object-fit: none;
}
::v-deep {
  .el-tabs__nav-wrap::after {
    height: 1px;
  }
  .el-tabs__nav {
    width: 100% !important;
  }
  .el-tabs__item {
    width: 33.33% !important;
    text-align: center;
    height: 60px;
    line-height: 60px;
    .custom-label {
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      color: #555;
      .svg-icon {
        margin-right: 6px;
      }
    }
    &:hover,
    &.is-active {
      .custom-label {
        color: #f80000;
      }
    }
  }
  .el-form-item--small.el-form-item {
    margin-bottom: 0px;
  }
}
.vip-content {
  cursor: pointer;
  h3 {
    text-align: center;
    margin-left: -40px;
  }
  .br-1 {
    border-right: 1px solid #ededed;
  }
  .center {
    text-align: center;
    color: #777;
    margin-bottom: 15px;
  }
  .vip-desc {
    height: 400px;
    padding: 0 20px 0 10px;
    ::v-deep {
      .el-form-item__content {
        color: #f80000;
      }
    }
  }
  .vip-price {
    padding: 20px;

    ::v-deep {
      .el-badge {
        width: 100%;
      }
      .el-badge__content.is-fixed {
        right: initial;
        left: 100%;
        transform: translate(-100%, -50%);
      }
    }
  }
  .price {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 140px;
    div {
      margin-top: 15px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-size: 16px;
      color: #555;
      b {
        font-size: 28px;
        line-height: 28px;
      }
    }
    p {
      margin: 16px 0 12px;
      text-align: center;
      color: #666;
    }
    span {
      color: #777;
    }
    ::v-deep .el-input-number {
      margin-top: 20px;
      width: 100px;
    }
  }
  .price-qr {
    display: flex;
    flex-direction: column;
    align-items: center;
    .qr {
      width: 168px;
    }
    div {
      color: #f80000;
      padding: 5px 0;
      b {
        font-size: 24px;
      }
    }
    p {
      color: #555;
    }
  }
}
.vip-tips {
  margin-top: 30px;
  p {
    margin-bottom: 8px;
    color: #555;
  }
}

.price-qr {
  text-align: center;
  div {
    color: #f80000;
    padding: 5px 0;
    b {
      font-size: 24px;
    }
  }
}
</style>
